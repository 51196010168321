import React from "react";
import Helmet from "react-helmet";
import { Container } from "react-bootstrap";
import { Link } from "gatsby";

class TermsAndConditions extends React.Component {
  render() {
    return (
      <div className="termsAndConditions">
        <Helmet>
          <title>Uvjeti i odredbe</title>
        </Helmet>
        <Container>
          <Link className="terms" to="/">
            Početna
          </Link>
          <h1 className="pageTitle">Uvjeti i odredbe</h1>
          <p>
            1. OPĆE ODREDBE KORIŠTENJA WEB TRGOVINE I DEFINICIJE
            <br />
            1.1. Ovim se Općim uvjetima uređuje korištenje Terraform web
            trgovine na web adresi https://www.terraform.hr. Molimo korisnike da
            pažljivo pročitaju ove uvjete i odredbe prije korištenja. Vaša
            suglasnost s ovdje navedenim uvjetima i odredbama je dobar preduvjet
            za izvršavanje kvalitetne kupnje. Ove Opće odredbe i uvjete
            prihvaćate elektronskim putem prilikom potvrđivanja narudžbe u web
            trgovini. <br />
            1.2. Opći uvjeti web trgovine Terraform sastavljeni su u skladu sa
            Zakonom o zaštiti potrošača, Zakonom o zaštiti osobnih podataka te
            Zakonom o elektroničkoj trgovini koji su na snazi na području
            Republike Hrvatske. <br /> 1.3. U Općim uvjetima pojmovi pisani
            podebljanim slovima imat će značenje dano u nastavku: • Korisnik
            znači svaku osobu, pravnu ili fizičku, koja pristupa web stranicama.
            Pojam korisnik uključuje i registriranog korisnika. • Registrirani
            korisnik znači svaku potpuno poslovno sposobnu osobu, pravnu ili
            fizičku, koja se registrirala za korištenje usluge Web trgovine na
            web stranicama. • Terraform znači Obrt Terraform, vl. Luka Grgić,
            Dunavska 14 Slavonski Brod, OIB: 36670123504, pod matičnim brojem
            obrta: 98340034, (dalje u tekstu: Terraform ili „Prodavatelj“).{" "}
            <br /> 1.4. Pristupom web stranicama putem odgovarajućeg tehničkog
            sredstva pristupa i njihovim korištenjem svaki korisnik se obvezuje
            poštivati ove Opće uvjete i pristaje da se na njega primjenjuju
            odredbe ovih Općih uvjeta. Ukoliko imate pitanja, možete nam se
            obratiti na e-mail adresu info@terraform.hr ili na kontakt podatke
            navedene na web stranici ‘Kontakt’. <br />
            1.5. Usluge Terraforma-a se sastoje od izrade biljnih terarija,
            obrade ravnog stakla prodaje istih između korisnika web trgovine,
            tj. vas kao kupca i nas kao prodavatelja, te organiziranja dostave
            predmeta prodaje. Uslugu web trgovine moguće je koristiti isključivo
            na teritoriju država Europske unije. <br />
            2. IZMJENE OPĆIH UVJETA <br /> 2.1. Terraform zadržava pravo
            izmijeniti ili ukinuti ove Opće uvjete bez prethodne najave.
            Korisnici su dužni prilikom svakog korištenja web stranica,
            provjeriti važeće Opće uvjete. Važeći opći uvjeti bit će objavljeni
            na web stranicama.
            <br /> 2.2. Za slučaj sporova, primijenit će se verzija koja je bila
            važeća u trenutku izvršenja kupnje i s kojom ste kao korisnik
            izrazili svoju suglasnost u trenutku predavanja narudžbe. <br />
            2.3. Ovim putem Vas obavještavamo da postoji mogućnost povremene
            promjene Općih odredbi i uvjeta korištenja web trgovine radi
            usklađivanja s promjenama zakona ili našim poslovnim potrebama.
            Tehnički nismo u mogućnosti svakog korisnika obavijestiti o
            eventualnim promjenama, stoga Vam preporučamo da pročitate ovaj
            tekst prilikom ponovnog korištenja web trgovine kako biste se
            upoznali s mogućim promjenama.
            <br /> 3. REGISTRACIJA KORISNIKA <br />
            3.1. Registracija korisnika se vrši putem web stranica, prilikom
            čega će korisnik biti obvezan upisati određene osobne podatke i
            pristati na korištenje takvih osobnih podataka.
            <br />
            3.2. Nakon uspješne registracije, registrirani korisnik dobiva na
            korištenje korisničko ime i lozinku koji služe za autorizaciju
            korištenja Webtrgovine <br />
            3.3. Registracijom korisnik potvrđuje: – da u cijelosti prihvaća ove
            opće uvjete; – potpunost, točnost, istinitost i ažurnost osobnih
            podataka; – da daje Terraformu-u izričitu suglasnost da može
            sukladno odredbama Zakona o zaštiti osobnih podataka, obrađivati
            dane osobne podatke za potrebe vlastitih evidencija i pružanja
            drugih usluga, potrebe stvaranja baze podataka o kupcima,
            obavještavanja o novim proizvodima i uslugama. <br />
            3.4. Registrirani korisnik pristaje da se kupnja putem Web trgovine
            vrši na način da registrirani korisnik kupuje proizvode i usluge
            dostupne putem Web trgovine (“proizvodi”) na način da ih bira na
            temelju slike i osnovnog opisa proizvoda.
            <br /> 3.5. Narudžba proizvoda se vrši elektronskim putem. Klikom na
            ikonu “dodaj u košaricu”, odabrani proizvod sprema se u potrošačku
            košaricu. Za nastavak procedure potrebno je kliknuti “sadržaj
            košarice” gdje se nalaze svi odabrani artikli. Roba se smatra
            naručenom u trenutku kada kupac prođe cijeli proces narudžbe. Na
            e-mail adresu kojom ste se registrirali kao kupac, dobit ćete
            potvrdu da je narudžba zaprimljena od strane Terraforma-a.
            <br /> 3.6. Ukoliko Terraform nije u mogućnosti isporučiti neki od
            naručenih proizvoda, kontaktirat će kupca telefonski ili putem
            e-mail-a te ga obavijestiti o navedenom. Kupac ima pravo odustati od
            narudžbe takvog proizvoda ili zatražiti zamjenski proizvod.
            <br /> 4. SKLAPANJE UGOVORA O KUPOPRODAJI
            <br /> 4.1. Ovi uvjeti kao i pojedini uvjeti naznačeni uz proizvode
            predstavljaju ponudu Terraform-a za sklapanje Ugovora.
            <br /> 4.2. Predmet Ugovora je kupnja odabranog proizvoda ili usluge
            putem web trgovine www.terraform.hr. Ove Opće odredbe i uvjeti čine
            sastavne dijelove ugovora o kupoprodaji sklopljenog putem web
            trgovine. <br />
            4.3. Prilikom pregledavanja sadržaja Terraform web trgovine možete
            slobodno birati željene artikle i dodavati ih u košaricu. Nakon što
            ste odabrali željene artikle i sigurni ste da ih želite kupiti,
            krećete u proces narudžbe prilikom kojeg unosite sve podatke
            potrebne za izvršenje narudžbe, potvrđujete svoju suglasnost sa
            Općim odredbama i uvjetima korištenja web trgovine, te ovisno o
            odabranom načinu plaćanja, izvršavate plaćanje.
            <br /> 4.4. Radnje koje ste izvršili i potvrdili prilikom procesa
            predavanja narudžbe smatraju se sklapanjem ugovora o kupoprodaji i
            kao takve su obvezujuće. Ugovor stupa na snagu od trenutka kada
            korisnih prihvati ponudu Terraforma-a, u ovom slučaju kada potvrdi
            sve izvršene radnje.
            <br /> 4.5. Proces potvrde ponude odvijat će se na način da ćete
            nakon predavanja Vaše narudžbe, primiti na e-mail adresu koju ste
            upisali u procesu narudžbe automatski generiranu potvrdu o primitku
            narudžbe (u daljnjem tekstu ‘Potvrda’). Potvrda o primitku narudžbe
            ne smatra se potvrdom sklapanja ugovora o kupoprodaji od strane
            prodavatelja, već je isključivo dokument kojim se potvrđuje primitak
            narudžbe.
            <br /> 5. CIJENE PROIZVODA I USLUGA PUTEM WEB TRGOVINE <br /> 5.1.
            Cijene proizvoda izražene su u hrvatskim kunama (“HRK”). <br /> 5.2.
            Cijene proizvoda su podložne promjenama do trenutka potvrde
            kupovine. Naručena roba isporučuje se po cijenama i uvjetima važećim
            na web trgovini na dan predavanja narudžbe, bez obzira na cijene i
            uvjete važeće na dan isporuke. <br />
            5.3. Potvrdom kupovine registrirani korisnici završavaju postupak
            kupovine putem web trgovine. <br />
            5.4. Cijena dostave kupljenih proizvoda nije uključena u cijenu
            proizvoda. <br /> 5.5. Račun ćete primiti u paketu zajedno s robom
            ili kao posebnu pošiljku, ovisno o mjestu otpreme robe. <br />
            5.6. Sva plaćanja će biti izvršena u Hrvatskoj kuni. Cijene
            prikazane u drugim valutama su okvirne. Pri naplati pretvorbu će
            odraditi pružatelj kreditnig usluga. <br />
            6. RASKID UGOVORA O KUPOPRODAJI
            <br />
            6.1. Sukladno Zakonu o zaštiti potrošača korisnik ima pravo Ugovor
            jednostrano raskinuti u roku od 14 dana bez navođenja razloga.{" "}
            <br />
            6.2. Da bi ostvario pravo na jednostrani raskid Ugovora, korisnik
            nas mora obavijestiti o svojoj odluci o jednostranom raskidu ugovora
            i to nedvosmislenom izjavom poslanom poštom ili elektroničkom
            poštom, u kojoj ćete navesti svoje ime i prezime, adresu, broj
            telefona, telefaksa ili adresu elektroničke pošte. <br />
            6.3. Ako korisnik jednostrano raskine Ugovor, izvršit ćemo povrat
            novca koji smo od korisnika primili, isključujući troškove isporuke
            i to bez odgađanja, a najkasnije u roku od 14 dana od dana kada smo
            zaprimili korisnikovu odluku o jednostranom raskidu ugovora. <br />
            6.4. Trošak zamjene proizvoda snosi kupac u istom iznosu u kojem mu
            je roba dostavljena, osim u slučaju kada je za troškove povrata
            proizvoda moguće odabrati jeftiniju opciju, u tom slučaju kupac može
            snositi troškove zamjene proizvoda u iznosu manjem od iznosa u kojem
            mu je roba dostavljena.
            <br /> 6.5. U slučaju raskida ugovora, svaka je strana dužna vratiti
            drugoj ono što je primila na temelju Ugovora, osim za troškove
            navedene pod stavkom <br />
            6.6. Prema članku 72. Zakona o zaštiti potrošača, dužni ste
            primljenu robu vratiti na adresu Dunavska 14, 35000 Slavonski Brod,
            Hrvatska. Povrat novca bit će izvršen na isti način na koji ste Vi
            izvršili uplatu. <br />
            6.7. Povrat novca možemo izvršiti tek nakon što nam roba bude
            vraćena. Korisnik robu treba poslati ili je predati nama bez
            nepotrebnog odgađanja, a u svakom slučaju najkasnije u roku od 14
            dana od dana kada nam je uputio odluku o jednostranom raskidu
            ugovora. <br />
            6.8. U slučaju korisnikovog jednostranog raskida ugovora izravne
            troškove povrata robe mora snositi korisnik.
            <br /> 6.9. U slučaju korisnikovog jednostranog raskida ugovora
            korisnik je odgovoran za svako umanjenje vrijednosti robe koju
            vraća, koje je rezultat rukovanja robom, osim onog koje je bilo
            potrebno za utvrđivanje prirode, obilježja i funkcionalnosti robe.{" "}
            <br />
            6.10. Za materijalne nedostatke odgovaramo sukladno odredbama Zakona
            o obveznim odnosima.
            <br />
            6.11. Ukoliko je došlo do pogreške prilikom pakiranja naručenih
            proizvoda te korisnik nije dobio naručeni proizvod već neki drugi, u
            tom slučaju, korisnik vraća krivo isporučen proizvod na trošak
            Terraforma-a koji se obvezuje isporučiti naručeni proizvod ili
            izvršiti povrat novca (sukladno zahtjevu korisnika) u roku od 14
            dana od zaprimljenog krivo isporučenog artikla. <br />
            6.12. Jedini preduvjet jednostranog raskida Ugovora jest da roba
            nije korištena te da se nalazi u originalnoj ambalaži s originalnim
            etiketama. <br />
            7. PRAVA I OBVEZE PRODAVATELJA <br />
            7.1. Dužnost Terraforma-a je dostaviti Vam prodanu stvar u vrijeme i
            na način kako je to navedeno u predočenim uvjetima poslovanja, nakon
            što izvršite narudžbu i platite kupoprodajnu cijenu. Sukladno Zakonu
            o obveznim odnosima Republike Hrvatske, Terraform odgovara za
            materijalne nedostatke robe koju prodaje na web trgovini.
            <br /> 7.2. Terraform mora osigurati istinite informacije o
            ponuđenoj robi i pružiti potpune informacije o plaćanju kupoprodajne
            cijene i isporuci robe. <br />
            7.3. Ukoliko je web trgovina privremeno nedostupna ili dostupna samo
            u ograničenom opsegu, zbog nadogradnje sustava, tehničkih poteškoća,
            problema više sile ili drugih uzroka, Terraform nije odgovoran, bez
            obzira na uzrok i trajanje, za eventualnu nedostupnost web trgovine,
            bilo kakvo kašnjenje ili prekid u prijenosu informacija, djelomični
            ili potpuni prekid ili pogrešan rad i/ili tehničke probleme koji
            mogu dovesti do pogrešne obrade podataka te bilo kakva potraživanja
            ili gubitke koja iz tog proizlaze.
            <br /> 7.4. U slučaju kvara javnih ili privatnih telekomunikacijskih
            mreža, Terraform neće biti odgovoran za bilo kakvu nemogućnost
            izvršenja ili kašnjenje u izvršenju neke od obveza koje ima na
            temelju ovih Uvjeta ili nekog od ugovora u slučaju da je to rezultat
            neke radnje ili događaja koji su izvan razumne kontrole
            prodavatelja. U takvim situacijama, prodavatelj će pokušati ispuniti
            svoje obveze u najkraćem roku. <br />
            7.5. Prodavatelj nije obvezan na temelju primljene narudžbe sklopiti
            ugovor o kupoprodaji u slučaju kada ne može u potpunosti izvršiti
            obveze iz ugovora. Ugovor o kupoprodaji od strane prodavatelja
            smatra se sklopljenim u trenutku otpreme robe o čemu ćete biti
            obaviješteni posebnom e-mail porukom.
            <br />
            8. PRAVA I OBVEZE KUPCA <br />
            8.1. Kupac je dužan platit cijenu određenu ugovorom. Također je
            dužan platiti troškove dostave ukoliko su isti uključeni u ukupnu
            vrijednost narudžbe, te preuzeti kupljene proizvode prilikom
            isporuke. <br />
            8.2. Ukoliko je kupac nezadovoljan kupljenim proizvodom, ima pravo
            na reklamaciju te se obratiti prodavatelju prema uputama. <br />
            8.3. Kupac je odgovoran za svako umanjenje vrijednosti robe od
            trenutka njezinog preuzimanja, koje nastane kao rezultat rukovanja
            robom. <br />
            8.4. Korisnik se obvezuje da neće koristiti web stranicu na bilo
            koji način koji je protivan pozitivnim propisima ili koristiti web
            stranicu za slanje elektroničke pošte protivne ovim Općim uvjetima i
            unositi ili distribuirati putem web stranice bilo kakav nelegalan
            software, uključujući ali se ne ograničavajući na viruse, spyware,
            trojane, ili slično. <br />
            8.5. Kupac pravo na jednostrani raskid kupoprodajnog ugovora ima u
            roku od 14 dana od primitka robe <br />
            9. PRITUŽBE I NAČIN RJEŠAVANJA SPOROVA <br />
            9.1. Korisnik nas može kontaktirati te uputiti pritužbu na e-mail
            adresu: info@terraform.hr,u slučaju da uoči pogrešku, problem,
            povredu, neprihvatljiv sadržaj i slično objavljen u web trgovini ili
            na web stranicama Terraforma-a. Na svaki pristojni pisani prigovor
            odgovorit ćemo u roku od 15 dana od njegovog primitka. <br /> 9.2.
            Na ove Opće uvjete i ugovore primjenjuje se hrvatsko pravo <br />{" "}
            9.3. U slučaju nastanka spora obavještavamo Vas o svojoj dobroj
            volji da svaki spor riješimo mirnim putem sporazumno, kao i vašem
            pravu da koristite mehanizme izvansudskog rješavanja potrošačkih
            sporova pravom pokretanja postupka izvansudskog rješavanja spora
            podnošenjem prijave Sudu časti Hrvatske gospodarske Komore, i/ili
            podnošenjem prijedloga za mirenje Centru za mirenje pri Hrvatskoj
            gospodarskoj Komori. <br /> 9.4. U slučaju sudskog postupka nadležan
            je stvarno nadležni sud u Slavonskom Brodu.
          </p>
        </Container>
      </div>
    );
  }
}

export default TermsAndConditions;
